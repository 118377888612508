import CarvelPollFooterData from "../../data/Footer/CarvelPollFooter.data"
import { SocialURLData } from "../../data/Social.data";

export default function CarvelPollFooter() {
  const socialData = SocialURLData.carvel;

  return (
    <footer className="main-footer">
      <div className="nav-wrapper">
        <div className="container">
          <img
            src={CarvelPollFooterData.logo.path}
            alt={CarvelPollFooterData.logo.alt}
            className="logo"
          />
          <div className="site-tools">
            <nav className="auxiliary-navigation">
              <ul>
                {CarvelPollFooterData.tools.map(link => {
                  return (
                    <li className="nav-item">
                      <a
                        href={link.href}
                        className={[
                          "nav-link",
                          link.thirdParty ? "fcs-third-party" : ""
                        ].join(" ")}
                        target="_blank"
                      >
                        {link.text}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="connect-social">
        <div className="container">
          <h3>Stay Connected</h3>
          <ul>
            {socialData.map(social => {
              return (
                <li>
                  <a
                    href={social.url}
                    target="_blank"
                  >
                    <img
                      src={social.src}
                      alt={social.alt}
                    />
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="nav-wrapper secondary">
        <div className="container">
          <p>© 2023 Carvel Franchisor SPV LLC. All rights reserved.</p>
          <div className="site-tools">
            <nav className="auxiliary-navigation">
              <ul>
                {CarvelPollFooterData.copyright.map(link => {
                  return (
                    <li className="nav-item">
                      <a
                        href={link.href}
                        className={[
                          "nav-link",
                          link.thirdParty ? "fcs-third-party" : ""
                        ].join(" ")}
                        target="_blank"
                      >
                        {link.text}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="fcs_ada-wrapper">
        <div className="container">
          <div className="fcs_rte-wrapper">
            <p>
              <span className="fcs-third-party"></span>
              <span className="sr-only">external</span>
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              indicates link opens an external site which may or may not meet accessibility guidelines.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}