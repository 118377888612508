export const SocialURLData ={
  mcalisters: [
    {
      url: "https://www.facebook.com/mcalistersdeli",
      src: "/common/social/facebook.png",
      alt: "Facebook, opens new window"
    },
    {
      url: "https://twitter.com/McAlistersDeli",
      src: "/common/social/twitter.png",
      alt: "Twitter, opens new window"
    },
    {
      url: "https://www.instagram.com/mcalistersdeli/",
      src: "/common/social/instagram.png",
      alt: "Instagram, opens new window"
    }
  ],
  cinnabon: [
    {
      url: "https://www.facebook.com/Cinnabon/",
      src: "/common/social/facebook-circle.png",
      alt: "Stay Connect with Cinnabon on Facebook"
    },
    {
      url: "https://twitter.com/Cinnabon",
      src: "/common/social/twitter-circle.png",
      alt: "Stay Connect with Cinnabon on Twitter"
    },
    {
      url: "https://www.instagram.com/cinnabon/",
      src: "/common/social/instagram-circle.png",
      alt: "Stay Connect with Cinnabon on Instagram"
    }
  ],
  carvelBackup: [
    {
      url: "https://www.facebook.com/CarvelIceCream/",
      src: "/common/social/facebook-cartoonish.png",
      alt: "Carvel on Facebook"
    },
    {
      url: "https://twitter.com/CarvelIceCream",
      src: "/common/social/twitter-cartoonish.png",
      alt: "Carvel on Twitter"
    },
    {
      url: "https://www.instagram.com/carvelicecream/?hl=en",
      src: "/common/social/instagram-cartoonish.png",
      alt: "Carvel on Instagram"
    }
  ],
  carvel: [
    {
      url: "",
      src: "",
      alt: ""
    }
  ],
  auntieannes: [
    {
      url: "https://www.facebook.com/auntieannespretzels",
      src: "/common/social/facebook-circle.png",
      alt: "Facebook external"
    },
    {
      url: "https://twitter.com/auntieannes",
      src: "/common/social/twitter-circle.png",
      alt: "Twitter external"
    },
    {
      url: "http://instagram.com/auntieannespretzels",
      src: "/common/social/instagram-circle.png",
      alt: " Instagram external"
    },
    {
      url: "https://www.tiktok.com/@auntieannes",
      src: "/common/social/tiktok-circle.png",
      alt: "TikTok external"
    }
  ]
}