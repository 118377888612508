export default {
  logo: {
    path: "/carvel/logo-white.png",
    alt: "Carvel's Logo"
  },
  tools: [
    {
      href: "https://www.carvel.com/about-us",
      text: "About"
    },
    {
      href: "https://www.carvel.com/coupon",
      text: "Coupons"
    },
    {
      href: "https://www.carvel.com/sitemap",
      text: "Sitemap"
    },
    {
      href: "https://www.carvel.com/gift-cards",
      text: "Gift Cards"
    },
    {
      href: "https://www.carvel.com/about-us/careers",
      text: "Careers"
    },
    {
      href: "https://www.carvel.com/nutrition",
      text: "Nutrition"
    },
    {
      href: "https://development.focusbrands.com/",
      text: "Franchise",
      thirdParty: true
    },
    {
      href: "https://www.carvel.com/newsroom",
      text: "Press"
    },
    {
      href: "https://survey3.medallia.com/?carvel-contact-us&br=2",
      text: "Contact Us"
    },
    {
      href: "https://www.carvel.com/faqs",
      text: "FAQ",
      thirdParty: true
    },
    {
      href: "https://www.carvel.com/join-fudgie-fanatics",
      text: "Rewards"
    },
  ],
  copyright: [
    {
      href: "https://www.focusbrands.com/accessibility/carvel/",
      text: "Accessibility"
    },
    {
      href: "https://www.focusbrands.com/animal-welfare/",
      text: "Animal Welfare"
    },
    {
      href: "https://www.carvel.com/legal",
      text: "Legal"
    },
    {
      href: "https://www.carvel.com/privacy",
      text: "Privacy"
    },
    {
      href: "https://www.carvel.com/privacy#ca",
      text: "CA Privacy"
    },
    {
      href: "https://www.carvel.com/#cookieBanner",
      text: "Your Privacy Choices"
    },
  ]
}